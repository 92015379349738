<template>
  <div
    class="detail flex"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <div class="detail_left">
      <div class="container">
        <h1>{{ detail.name }}</h1>
        <div class="time" v-if="detail.countInfo">
          <span v-show="detail.createDate">时间：{{ detail.createDate }}</span>
          <el-divider
            direction="vertical"
            v-if="detail.createDate"
          ></el-divider>
          <span>阅读 {{ detail.clicks }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>点赞 {{ detail.countInfo.goods }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>收藏 {{ detail.countInfo.collections }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>评论 {{ detail.countInfo.recomands }}</span>
        </div>
        <div class="text" v-html="detail.content"></div>
      </div>

      <div class="bottom" id="comment">
        <div class="comment text_r">
          <div class="login" v-if="login == 0">
            <el-button type="primary" size="mini" @click="isLogin"
              >登录</el-button
            >
            <span>后发表评论</span>
          </div>
          <el-input
            type="textarea"
            v-model="form.content"
            rows="5"
            class="textarea"
            placeholder="发表你的评论"
            resize="none"
            maxlength="300"
            show-word-limit
            v-if="login == 1"
          ></el-input>
          <el-checkbox v-model="form.isShowName" title="是否匿名" class="checked"
            >匿名</el-checkbox
          >
          <el-button
            :type="form.content.length > 0 ? 'primary' : 'info'"
            :disabled="form.content.length > 0 ? false : true"
            size="mini"
            class="info_btn"
            @click="commentSave()"
            v-loading="loadings"
            ><a href="#more">评论</a>
          </el-button>
        </div>
        <div class="title">
          全部评论 <span>{{ page.total }}</span>
        </div>
        <ul class="list">
          <li class="box" v-for="(n, i) in list" :key="i">
            <div class="info flex" v-if="n.memberUserInfo">
              <img
                :src="n.memberUserInfo.image"
                :alt="n.memberUserInfo.name"
                class="avatar err_avatar"
                @click="goRouter('/section/sectionCenter', n.id)"
              />
              <div class="ins">
                <div class="name">{{ n.memberUserInfo.name }}</div>
                <div class="text">
                  {{ n.content }}
                </div>
                <div class="time relative">
                  <span>{{ n.createDate }}</span>
                  <span
                    class="praise"
                    :class="n.isGood == 0 ? '' : 'success'"
                    @click="saveClomunReplayGood(n.id, n)"
                  >
                    <img
                      src="~/static/ask/zan.png"
                      alt="点赞"
                      v-show="n.isGood == 0"
                    />
                    <img
                      src="~/static/ask/zan_green.png"
                      alt=""
                      v-show="n.isGood == 1"
                      class="green"
                    />{{ n.goods }}</span
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="more flex" @click="mores" v-if="list.length != page.total">
          <p></p>
          <p>查看更多评论</p>
          <a href="#comment"><i class="el-icon-edit"></i>写评论</a>
        </div>
        <div id="more"></div>
      </div>
    </div>
    <div class="detail_right">
      <div class="top" v-if="detail.authorInfo">
        <div
          class="avatar cursorP"
          @click="goRouter('/section/sectionCenter', detail.authoreid)"
        >
          <img
            :src="detail.authorInfo.avatar"
            :alt="detail.authorInfo.realname"
            :title="detail.authorInfo.realname"
          />
          <div class="name">{{ detail.authorInfo.realname }}</div>
          <div class="text">
            {{ detail.authorInfo.breifdescrition }}
          </div>
          <div class="attention" @click.stop="look(detail.authoreid)">
            <el-button
              :type="attention == 0 ? 'primary ' : 'info'"
              size="mini"
              round
              :class="attention == 0 ? 'primary' : 'info_btn'"
            >
              <span v-if="attention == 0"
                ><i class="el-icon-plus"></i> 关注</span
              >
              <span v-else>已关注</span>
            </el-button>
          </div>
        </div>
        <div class="topic" v-for="(n, i) in detail.authorClomuns" :key="i">
          <router-link
            :to="'/section/sectionDetail?id=' + n.id"
            target="_blank"
            :title="n.name"
          >
            <div class="name line_clamp2">
              {{ n.name }}
            </div>
            <p>
              <span>阅读 {{ n.clicks }}</span>
            </p>
          </router-link>
        </div>
      </div>
      <div class="ad relative">
        <img src="~/static/pic9.png" alt="专家团队" />
        <div class="type">
          课程
        </div>
      </div>
    </div>
    <ul class="fixed" v-if="detail.userInfo">
      <li class="share ">
        <img src="~/static/column/share.png" alt="分享" />
        <p>分享</p>
        <Share
          :url="shareUrl"
          :title="detail.name"
          :desc="detail.gaidesc"
          fc="50"
        ></Share>
      </li>
      <li @click="likeOk(detail.id, detail.userInfo)">
        <img
          src="~/static/column/like.png"
          alt="点赞"
          v-if="detail.userInfo.goods == 0"
        />
        <img src="~/static/column/zan_green.png" alt="" v-else />
        <p>{{ detail.userInfo.goods == 0 ? "点赞" : "已点赞" }}</p>
      </li>
      <li @click="collect(detail.id, detail.userInfo)">
        <img
          src="~/static/column/collect.png"
          alt="收藏"
          v-if="detail.userInfo.collections == 0"
        />
        <img src="~/static/column/collect_green.png" alt="" v-else />
        <p>{{ detail.userInfo.collections == 0 ? "收藏" : "已收藏" }}</p>
      </li>
    </ul>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      title="登录"
      :text="title"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
import Share from "@/components/public/Collect";
export default {
  name: "columnDetail",
  components: {
    loginPrompt,
    Share,
  },
  data() {
    return {
      detail: [],
      list: [],
      login: 0,
      form: {
        content: "",
        isShowName: false,
        spcialClomunId: "",
      },
      attention: 0,
      loading: false,
      loadings: false,
      page: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        id: "",
      },
      isHide: false,
      title: "",
      loginType: "",
      shareUrl: "",
    };
  },
  methods: {
    async info() {
      this.loading = true;
      let id = this.$route.query.id;
      let res = await this.$api.sectionDetailApi({ id: id });
      if (res.data.success) {
        this.loading = false;
        this.detail = res.data.data;
        document.title = this.detail.name + +"-兰州市残疾人就业创业网络服务平台";
        this.attention = res.data.data.userInfo.focus;
        this.shareUrl = window.location.href;
        this.loginType = localStorage.getItem("userType");
        if (this.loginType) {
          this.login = 1;
        } else {
          this.login = 0;
        }
      }
    },
    async comment() {
      this.page.id = this.$route.query.id;
      var res = await this.$api.queryClomunReplayListApi(this.page);
      if (res.data.success) {
        this.list = this.list.concat(res.data.data.records);
        this.page.total = res.data.data.total;
      }
    },
    look(id) {
      if (localStorage.getItem("userType")) {
        this.$api.focusAuthorApi({ id: id }).then((res) => {
          if (res.data.success) {
            this.attention = 1 ^ this.attention;
            if (this.attention) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          }
        });
      } else {
        this.title = "登录成功后才可已关注！";
        this.isHide = true;
      }
    },
    //点赞
    likeOk(id, zan) {
      if (localStorage.getItem("userType")) {
        this.$api.collectCloumnApi({ id: id }).then((res) => {
          zan.goods = 1 ^ zan.goods;
          if (zan.goods) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.title = "登录成功后才可以点赞！";
        this.isHide = true;
      }
    },
    //收藏
    collect(id, type) {
      if (localStorage.getItem("userType")) {
        this.$api.saveClomunCollectionApi({ id: id }).then((res) => {
          type.collections = 1 ^ type.collections;
          if (type.collections) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.title = "登录成功后才可以收藏！";
        this.isHide = true;
      }
    },
    commentSave() {
      this.form.spcialClomunId = this.$route.query.id;
      if (this.form.isShowName) {
        this.form.isShowName = 1;
      } else {
        this.form.isShowName = 0;
      }
      this.loadings = true;
      this.$api
        .commentSaveApi(this.form)
        .then((res) => {
          if (res.data.success) {
            this.loadings = false;
            this.$message.success(res.data.msg);
            this.form.content = "";
            this.page.pageNum = 1;
            this.comment();
          }
        })
        .catch((err) => {
          this.loadings = false;
        });
    },
    saveClomunReplayGood(id, info) {
      this.$api.saveClomunReplayGoodApi({ id: id }).then((res) => {
        if (res.data.success) {
          info.isGood = 1 ^ info.isGood;
          if (info.isGood) {
            info.goods = Number(info.goods) + 1;
            this.$message.success(res.data.msg);
          } else {
            info.goods = Number(info.goods) - 1;
            this.$message.error(res.data.msg);
          }
        } else {
          this.title = "登录成功后才可以点赞！";
          this.isHide = true;
        }
      });
    },
    mores() {
      this.page.pageNum += 1;
      this.comment();
    },
    isLogin() {
      this.isHide = true;
      this.title = "登录后才可以评论";
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    handleClose() {
      this.isHide = false;
    },
  },
  created() {
    this.info();
    this.comment();
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/community/cloumn/detail?id=" +
        this.$route.query.id
    );
  },
  mounted() {
    this.$emit("goRouter", "/section/");
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/column.less";

/deep/ .el-loading-spinner i {
  color: #ffffff;
  margin-right: 10px;
}

.el-loading-spinner {
  background: #00924c;
  display: flex;
  height: 100%;
  line-height: 100%;
  justify-content: center;
  align-items: center;

  .el-loading-text {
    color: #ffffff;
  }
}
</style>
